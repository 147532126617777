import React, { useState, useEffect } from 'react';

function App() {
  const [ws, setWs] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const socket = new WebSocket('ws://3.138.121.229:8080/ws'); // Make sure the URL is correct
    socket.onopen = () => {
      console.log('WebSocket Connected');
    };
    socket.onmessage = (event) => {
      setMessage(event.data);
    };
    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
    setWs(socket);
    return () => socket.close();
  }, []);

  const handleClick = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send('buttonClicked');
    } else {
      console.error('WebSocket is not open.');
    }
  };

  return (
    <div className="App">
      <button onClick={handleClick}>Click Me</button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default App;
